import ArticleDiv from "../components/article-div"
import BaseLayout from "../layouts/base-layout"
import IDataPageProps from "../interfaces/data-page-props"
import * as React from "react"

import Seo from "../layouts/seo"
import { graphql } from "gatsby"
import BaseImage from "../components/base-image"
//import axios from "axios"
import BaseRow from "../components/base-row"
import BlueIndexLink from "../components/link/blue-index-link"
import BaseLink from "../components/link/base-link"
import HCenterRow from "../components/h-center-row"
import IndexPublications from "../components/index-publications"

export default function Page({ data, location }: IDataPageProps) {
  const publications = data.allPublications.publications

  return (
    <BaseLayout title="Home" showTitle={false} location={location}>
      <div className="mb-32 grid grid-cols-1 lg:grid-cols-2">
        <div className="hidden flex-col items-center justify-center bg-apple-gray lg:flex">
          <h1 className="py-8 text-7xl font-semibold">Welcome.</h1>
        </div>

        <BaseImage
          image={data.labImage}
          alt="Hands performing bench work."
          loading="eager"
          className="h-full w-full lg:-ml-16 lg:mt-16 lg:rounded-3xl lg:shadow-2xl"
        />
      </div>

      <h1 className="text-center text-7xl font-semibold lg:hidden">Welcome.</h1>

      {/* <div className="grid">
        <div
          className="z-0 h-120 w-full overflow-hidden"
          style={{
            gridArea: "1/1",
          }}
        >
          <BaseImage
            image={data.labImage}
            alt="Header Image"
            loading="eager"
            className="h-full w-full"
          />
        </div>
        <div
          className="z-20 h-full w-full bg-black/10 backdrop-blur-sm"
          style={{
            gridArea: "1/1",
          }}
        ></div>
        <VCenterCol
          className="z-30 h-full w-full items-center"
          style={{
            gridArea: "1/1",
          }}
        >
          <h1 className="text-7xl font-semibold text-white">Welcome.</h1>
        </VCenterCol>
      </div> */}

      <ArticleDiv className="my-32 lg:my-64">
        <></>
        <div className="grid grid-cols-1  gap-12 xl:grid-cols-5">
          <div className="col-span-3">
            <BaseLink
              href="https://pubmed.ncbi.nlm.nih.gov/25712152/"
              ariaLabel="View original article"
            >
              <BaseImage
                image={data.bcellImage}
                alt="Diagram of a B-cell."
                className="h-full w-full"
                imgStyle={{ objectFit: "contain" }}
              />
            </BaseLink>
          </div>
          <div className="col-span-2">
            <p className="text-xl">
              The research program in the Dalla-Favera laboratory is aimed at
              the identification of the molecular pathogenesis of human B cell
              malignancies. Functional and structural genomic approaches are
              used toward the identification of the genetic lesions leading to
              the transformation of B lymphocytes. The genomic landscape of the
              tumors is then recapitulated in genetically modified mice in order
              to construct models for pre-clinical testing of science-based new
              therapeutic modalities.
            </p>

            <BaseRow>
              <BlueIndexLink
                href="/people"
                className="mt-8 text-lg"
                ariaLabel="Meet the team"
              >
                Meet The Team
              </BlueIndexLink>
            </BaseRow>
          </div>
        </div>
        <></>
      </ArticleDiv>

      <ArticleDiv className="bg-gradient-to-b from-apple-gray">
        <></>
        <div className="mt-16 -mb-16 grid grid-cols-1 gap-16 rounded-3xl bg-white px-16 py-8 shadow-2xl lg:grid-cols-2 lg:px-64">
          <HCenterRow>
            <BaseLink
              href="https://pubmed.ncbi.nlm.nih.gov/32603407"
              ariaLabel="View article"
            >
              <BaseImage image={data.umapImage} alt="UMAP" />
            </BaseLink>
          </HCenterRow>

          <HCenterRow>
            <BaseLink
              href="https://pubmed.ncbi.nlm.nih.gov/32603407"
              ariaLabel="View article"
            >
              <BaseImage
                image={data.umapPseudotimeImage}
                alt="UMAP pseudo-time"
              />
            </BaseLink>
          </HCenterRow>
        </div>
        <></>
      </ArticleDiv>

      {/* <ArticleDiv className="mt-32">
        <></>

        <div className="grid grid-cols-1 gap-16 lg:grid-cols-2">
          <div className="">
            <h1 className="text-4xl font-semibold">Lorem ipsum dolor</h1>
            <p className="mt-8 text-lg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div>
            <BaseImage
              image={data.headerImage}
              imgClassName="overflow-hidden rounded-lg"
              className="h-96 w-full overflow-hidden rounded-lg shadow-lg"
              alt="Header image"
            />
          </div>
        </div>
        <></>
      </ArticleDiv> */}

      <IndexPublications publications={publications} />
    </BaseLayout>
  )
}

export const Head = () => <Seo title="Home" />

export const pageQuery = graphql`
  query IndexPageQuery {
    allPublications: publicationsJson(personId: { eq: "all" }) {
      personId
      publications {
        title
        journal
        year
        doi
        pmid
        pmcid
        abstract
        authorList
      }
    }

    headerImage: file(
      absolutePath: { regex: "/images/header/riccardo-dalla-favera.webp/" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    icgImage: file(absolutePath: { regex: "/images/icg.webp/" }) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    icgImage2: file(absolutePath: { regex: "/images/icg2.webp/" }) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    labImage: file(absolutePath: { regex: "/images/lab.webp/" }) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    bcellImage: file(absolutePath: { regex: "/images/bcell.webp/" }) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    umapImage: file(absolutePath: { regex: "/images/umap_bbbb.webp/" }) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    umapPseudotimeImage: file(
      absolutePath: { regex: "/images/umap_pseudotime.webp/" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
