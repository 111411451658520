// "use client"

import ArticleDiv from "./article-div"
import * as React from "react"

import { useEffect } from "react"
//import axios from "axios"
import TwoThirdsLayout from "../layouts/two-thirds-layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookOpen } from "@fortawesome/free-solid-svg-icons"
import BasePublication from "./publication/base-publication"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

interface IProps {
  publications: any[]
}

export default function IndexPublications({ publications }: IProps) {
  useEffect(() => {
    gsap.from(".publication", {
      opacity: 0,
      x: -20,
      stagger: 0.2,
      delay: 0.2,
      duration: 0.5,
      scrollTrigger: { trigger: "#publications" },
    })
  }, [])

  return (
    <ArticleDiv className="mt-64">
      <></>
      <div>
        <h1 className="text-center text-5xl font-bold">Recent Publications</h1>
        <TwoThirdsLayout
          className="mt-16 gap-8"
          hideBreakPoint="hidden 2xl:block"
          breakPoint="2xl:grid-cols-3"
        >
          {/* <BasePublicationList publications={publications.slice(0, 10)} /> */}
          <ul id="publications" className="flex flex-col gap-y-4">
            {publications.slice(0, 8).map((publication: any, index: number) => (
              <li key={index}>
                <BasePublication publication={publication} />
              </li>
            ))}
          </ul>

          <div className="relative text-gray-500">
            {publications.length > 0 && (
              <>
                <div
                  className="box-shadow absolute right-0 rounded-lg bg-white p-4 text-xs text-rose-300"
                  style={{ width: "80%" }}
                >
                  <div className="flex flex-col gap-2">
                    <div>
                      <FontAwesomeIcon
                        icon={faBookOpen}
                        className="h-4 text-rose-300"
                      />
                    </div>
                    <div>
                      <p className="font-bold">{publications[0].title}</p>
                      {/* <p>{publications[0].authorList.join(", ")}</p> */}
                    </div>
                  </div>
                </div>

                <div
                  className="box-shadow absolute rounded-lg bg-white p-4 text-xs text-blue-300"
                  style={{
                    width: "80%",
                    top: "8rem",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <div>
                      <FontAwesomeIcon
                        icon={faBookOpen}
                        className="h-4 text-blue-300"
                      />
                    </div>
                    <div>
                      <p className="font-bold">{publications[1].title}</p>
                      {/* <p>{publications[1].authorList.join(", ")}</p> */}
                    </div>
                  </div>
                </div>

                <div
                  className="box-shadow absolute rounded-lg bg-white p-4 text-xs text-emerald-400"
                  style={{
                    width: "80%",
                    top: "12rem",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <div>
                      <FontAwesomeIcon
                        icon={faBookOpen}
                        className="h-4 text-emerald-400"
                      />
                    </div>
                    <div>
                      <p className="font-bold">{publications[2].title}</p>
                      {/* <p>{publications[2].authorList.join(", ")}</p> */}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </TwoThirdsLayout>
      </div>
      <></>
    </ArticleDiv>
  )
}
